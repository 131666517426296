import React from 'react'

import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'

import { Avatar } from '@dentalux/ui-library-core/cjs/components/avatar'

import { Link } from '@mui/material'

import { CalendarIcon } from 'src/components/icons/Calendar'
import ClockIcon from 'src/components/icons/Clock'
import PractitionerDetails from 'src/components/PractitionerDetails'
import getPractitionerRoles from 'src/helpers/getPractitionerRoles'

import * as S from '../../AppointmentView.styles'
import { ViewProps } from '../../AppointmentView.types'
import DentalTreatments from '..//DentalTreatments'

const MobileView = ({
  date,
  time,
  treatmentTitle,
  treatments,
  practitioner,
  clinic,
  LocationLink,
  labels,
}: ViewProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const orientation = 'vertical'

  return (
    <>
      <S.SectionItem
        icon={<CalendarIcon />}
        orientation={orientation}
        label={t('b2c.appointments.appointmentSchedule')}
        value={
          <S.DateTime>
            {date}
            {time && [<ClockIcon key="time" />, time]}
          </S.DateTime>
        }
      />

      {practitioner && (
        <>
          <S.Divider />

          <S.SectionItem
            orientation={orientation}
            label={
              <PractitionerDetails
                size="small"
                avatar={<Avatar src={practitioner.imageUrl} />}
                title={
                  <NextLink href={`/practitioner/${practitioner.referenceId}`} passHref>
                    <Link target="_blank">{practitioner.name}</Link>
                  </NextLink>
                }
                subTitle={getPractitionerRoles(practitioner, t, language)}
                description={clinic.officialName}
              />
            }
          />
        </>
      )}

      {treatmentTitle && (
        <>
          <S.Divider />

          <S.SectionItem orientation={orientation} label={labels.treatment} value={treatmentTitle} />
        </>
      )}

      {treatments.length > 0 && (
        <S.SectionItem
          sx={{ pt: treatmentTitle ? 1 : 0 }}
          orientation={orientation}
          label={labels.details}
          value={<DentalTreatments data={treatments} />}
        />
      )}

      <S.Divider />

      {!!LocationLink && <S.SectionItem orientation={orientation} label={labels.findUs} value={LocationLink} />}

      <S.SectionItem
        sx={{ pt: LocationLink ? 1 : 0 }}
        label={practitioner ? labels.callClinic : labels.clinic}
        orientation={orientation}
        value={
          practitioner ? <Link href={`tel:${clinic.phoneNumber}`}>{clinic.phoneNumber}</Link> : clinic.officialName
        }
      />
    </>
  )
}

export default MobileView
