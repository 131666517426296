import { Language } from './Language'
import { Nullable } from './Nullable'
import { Gender } from './Person'

export enum PractitionerLevel {
  CHIEF = 'CHIEF',
  SENIOR = 'SENIOR',
  JUNIOR = 'JUNIOR',
  GRADUATE = 'GRADUATE',
}

export enum PractitionerRole {
  NONE = 'NONE',
  ASSISTANT = 'ASSISTANT',
  ACCOUNTANT = 'ACCOUNTANT',
  HYGIENIST = 'HYGIENIST',
  DENTIST = 'DENTIST',
  CLINIC_MANAGER = 'CLINIC_MANAGER',
  RECEPTIONIST = 'RECEPTIONIST',
  DENTAL_TECHNICIAN = 'DENTAL_TECHNICIAN',
}

export enum CvEntryEducationType {
  EDUCATION = 'EDUCATION',
  PROFESSIONAL_EXPERIENCE = 'PROFESSIONAL_EXPERIENCE',
  CERTIFICATE = 'CERTIFICATE',
}

export type PractitionerRating = {
  latestPersonalRatingForPractitioner: Nullable<number>
  latestPersonalRatingForPractitionerCreationDate: Nullable<string>
  avgScoreOfPractitioner: Nullable<number>
  countReviewsOfPractitioner: number
}

export type CvEntry = {
  description: Nullable<string>
  endDate: Nullable<string>
  startDate: string
  title: string
  type: CvEntryEducationType
}

export type DisplayRole = {
  en: Nullable<string>
  de: Nullable<string>
}

export type Practitioner = {
  referenceId: string
  specialisations: string[]
  clinicReferenceId: string
  cvEntries: CvEntry[]
  degree: string
  externalId: string
  name: string
  positions: PractitionerPosition[]
  favorite: boolean
  active: boolean
  imageUrl: Nullable<string>
  spokenLanguageCodes?: Array<keyof typeof Language>
  countryFlagCodes: Array<keyof typeof Language>
  title: string
  rating: Nullable<PractitionerRating>
  gender: Nullable<Gender>
  displayRole: DisplayRole
}

export type PractitionerPosition = {
  role: PractitionerRole
  level: Nullable<PractitionerLevel>
}
